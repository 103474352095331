import { Navigate, Route, Routes } from 'react-router-dom';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useSelector } from 'react-redux';
import SidebarLayout from './components/SidebarLayout';
import { Suspense, lazy } from 'react';
import SuspenseLoader from './components/SuspenseLoader';
import mapboxgl from 'mapbox-gl';
import Objects from './screens/drawer/management/objects';
import Teams from './screens/drawer/management/teams';
import Questions from './screens/drawer/management/questions';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('./screens/auth/login')));
const Users = Loader(lazy(() => import('./screens/drawer/management/teams')));
const SetTime = Loader(
  lazy(() => import('./screens/drawer/management/set-time'))
);

const App = (): JSX.Element => {
  // @ts-ignore
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // initialize mapbox
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<SidebarLayout />}>
            <Route path="/" element={<Navigate to={'/management/teams'} />} />
          </Route>
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/management/teams" element={<SidebarLayout />}>
            <Route path="/management/teams" element={<Teams />} />
          </Route>
          <Route path="/management/questions" element={<SidebarLayout />}>
            <Route path="/management/questions" element={<Questions />} />
          </Route>
          <Route path="/management/set-time" element={<SidebarLayout />}>
            <Route path="/management/set-time" element={<SetTime />} />
          </Route>
          <Route path="/management/add-time" element={<SidebarLayout />}>
            <Route path="/management/add-time" element={<SetTime />} />
          </Route>
          {/* <Route path="/management/objects" element={<SidebarLayout />}>
            <Route
              path="/management/objects"
              element={!isLoggedIn ? <Navigate to="/login" /> : <Objects />}
            />
          </Route> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
