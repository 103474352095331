import { Reducer, Slice, createSlice } from '@reduxjs/toolkit';
import { AdminModel } from 'src/models/adminModel';

const initialState: {
  isLoggedIn: boolean;
  adminData: AdminModel;
} = {
  isLoggedIn: localStorage.getItem('login') === '1',
  adminData:
    localStorage.getItem('login') === '1'
      ? JSON.parse(localStorage.getItem('adminData'))
      : undefined
};

const AuthSlice: Slice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    setLoginState(state, action) {
      state.isLoggedIn = action.payload;
    },
    setAdminData(state, action) {
      state.adminData = action.payload;
    }
  }
});

const authActions = AuthSlice.actions;
const authReducer: Reducer<any> = AuthSlice.reducer;

export { authActions, authReducer };
