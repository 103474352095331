import {
  Card,
  CardHeader,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
  LinearProgress,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  TextField,
  AlertColor,
  Alert,
  Snackbar,
  Stack,
  ButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IMG_BASE_URL } from "src/constants/constants";
import { QuestionModal } from "src/models/questionModal";
import {
  getAllQuestion,
  setTeamQuestion,
  updateTeamQuestion,
} from "src/networking/network_calls";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { editQuestionModal } from "src/models/editQuestionModal";

const Questions = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [dialogTitle, setDialogTitle] = useState("");
  const [questionList, setQuestionList] = useState<QuestionModal[]>([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [editModalData, setEditModalData] = useState<editQuestionModal>({
    id: 0,
    text: "",
    image: "",
    pre_image: "",
    password: "",
    time_in_minute: 0,
    time_in_seconds: 0,
  });

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  const getData = async () => {
    const res = await getAllQuestion();
    if (res && res.status === 200) {
      const dataList: QuestionModal[] = res?.data?.data;
      setQuestionList(dataList);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    getData();
  }, []);

  const setQuestion = async (payload: object) => {
    const res = await setTeamQuestion({ payload });
    if (res && res.status === 201) {
      setSnackBarState({
        show: true,
        msg: "Success",
        severity: "success",
      });
      getData();
    } else {
      setSnackBarState({
        show: true,
        msg: res.data["msg"] ?? "Failed",
        severity: "error",
      });
    }
  };

  const updateQuestion = async (payload: object) => {
    const res = await updateTeamQuestion({ payload });
    if (res && res.status === 201) {
      setSnackBarState({
        show: true,
        msg: "Success",
        severity: "success",
      });
      getData();
    } else {
      setSnackBarState({
        show: true,
        msg: res.data["msg"] ?? "Failed",
        severity: "error",
      });
    }
  };

  const openSetAddTimeDialog = () => {
    setOpen(true);
  };

  const closeSetAddTimeDialog = () => {
    setOpen(false);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarState({ ...snackBarState, show: false });
  };

  const setEditAction = async (data: QuestionModal) => {
    const time_in_seconds: number = parseInt(data.time_in_seconds) || 0; // Ensure valid number

    const time_in_minute: number = Math.floor(time_in_seconds / 60); // Calculate minutes
    const remaining_seconds: number = time_in_seconds % 60;
    setEditModalData({
      id: data.id,
      text: data.text,
      image: "",
      pre_image: data.image,
      password: data.password,
      time_in_minute: time_in_minute,
      time_in_seconds: remaining_seconds,
    });
    setEdit(true);
  };

  const imageHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          setEditModalData({
            ...editModalData,
            pre_image: reader.result as string,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const editCloseHandler = () => {
    setEdit(false);
    setEditModalData({
      id: 0,
      text: "",
      image: "",
      pre_image: "",
      password: "",
      time_in_minute: 0,
      time_in_seconds: 0,
    });
  };

  return (
    <Card sx={{ m: 2 }}>
      <Stack
        direction="row"
        gap={2}
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CardHeader
          subheaderTypographyProps={{}}
          titleTypographyProps={{}}
          title="Questions"
          subheader="All questions present in the system"
        />
        <ButtonGroup>
          <Button
            variant="contained"
            onClick={() => {
              openSetAddTimeDialog();
            }}
            style={{
              marginRight: "10px",
            }}
          >
            Add Question
          </Button>
        </ButtonGroup>
      </Stack>

      <Divider />
      <Snackbar
        open={snackBarState.show}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarState.severity}
          sx={{ width: "100%" }}
        >
          {snackBarState.msg}
        </Alert>
      </Snackbar>
      {loading && <LinearProgress />}
      {!loading && (
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Text</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Password</TableCell>
                  <TableCell>Time (in-second)</TableCell>
                  <TableCell>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questionList.map((data: QuestionModal) => (
                  <TableRow key={data.id} hover>
                    <TableCell>{data.text}</TableCell>
                    <TableCell>
                      <img
                        src={`${data.image}`} // Assuming it's a valid URL or path
                        alt="Question Image"
                        style={{ width: 50, height: 50 }}
                      />
                    </TableCell>
                    <TableCell>{data.password}</TableCell>
                    <TableCell>{data.time_in_seconds}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                          setEditAction(data);
                        }}
                      >
                        <BorderColorIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={closeSetAddTimeDialog}
        PaperProps={{
          component: "form",
          // @ts-ignore
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());

            const q_file = formJson.q_file;
            const q_text = formJson.q_text;
            const q_password = formJson.q_password;
            const minutes = formJson.minutes;
            const seconds = formJson.seconds;
            const timeInSeconds =
              (parseInt(minutes || "0") || 0) * 60 +
              (parseInt(seconds || "0") || 0);

            const payload: any = {
              image: q_file,
              q_text: q_text,
              q_password: q_password,
              timeInSeconds: timeInSeconds,
            };
            setQuestion(payload);

            closeSetAddTimeDialog();
          },
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Stack>
            <TextField
              autoFocus
              required
              margin="dense"
              id="q_file"
              name="q_file"
              label="Question Image"
              type="file"
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true, // This will force the label to appear above the file input
              }}
              InputProps={{
                style: {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                },
              }}
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="q_text"
              name="q_text"
              label="Text"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="q_password"
              name="q_password"
              label="Password"
              type="text"
              fullWidth
              variant="standard"
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <TextField
              autoFocus
              required
              margin="dense"
              id="minutes"
              name="minutes"
              label="Minutes"
              type="number"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="seconds"
              name="seconds"
              label="Seconds"
              type="number"
              fullWidth
              variant="standard"
            />
          </Stack>
          {/* </Box> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSetAddTimeDialog}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>

      {/*  ------------------   Edit Modal   ---------------------   */}

      <Dialog
        open={edit}
        onClose={editCloseHandler}
        PaperProps={{
          component: "form",
          // @ts-ignore
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            let id: number = 0; // Use `number` instead of `integer`
            if (editModalData && editModalData.id) {
              id = editModalData.id;
            }
            if (!id || id === 0) {
              setSnackBarState({
                show: true,
                msg: "Id is Required",
                severity: "error",
              });
              return false;
            }
            const q_file = formJson.edit_q_file || "";
            const q_text = formJson.edit_q_text;
            const q_password = formJson.edit_q_password;
            const minutes = formJson.edit_minutes;
            const seconds = formJson.edit_seconds;
            const timeInSeconds =
              (parseInt(minutes || "0") || 0) * 60 +
              (parseInt(seconds || "0") || 0);

            const payload: any = {
              id: id,
              image: q_file,
              q_text: q_text,
              q_password: q_password,
              timeInSeconds: timeInSeconds,
            };
            const resp: any = updateQuestion(payload);
            editCloseHandler();
          },
        }}
      >
        <DialogTitle>Edit</DialogTitle>

        <DialogContent>
          <Stack direction={"row"} textAlign={"center"}>
            <img
              src={`${editModalData.pre_image}`} // Assuming it's a valid URL or path
              alt="Question Image"
              style={{
                width: 100,
                height: 50,
                border: "2px solid black",
                padding: "5px",
              }}
            />
          </Stack>
          <Stack>
            <TextField
              autoFocus
              margin="dense"
              id="edit_q_file"
              name="edit_q_file"
              label="Question Image"
              type="file"
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true, // This will force the label to appear above the file input
              }}
              InputProps={{
                style: {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                },
              }}
              onChange={imageHandler}
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="edit_q_text"
              name="edit_q_text"
              label="Text"
              type="text"
              fullWidth
              variant="standard"
              value={editModalData.text}
              onChange={(e) =>
                setEditModalData({ ...editModalData, text: e.target.value })
              }
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="edit_q_password"
              name="edit_q_password"
              label="Password"
              type="text"
              fullWidth
              variant="standard"
              value={editModalData.password}
              onChange={(e) =>
                setEditModalData({ ...editModalData, password: e.target.value })
              }
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <TextField
              autoFocus
              required
              margin="dense"
              id="edit_minutes"
              name="edit_minutes"
              label="Minutes"
              type="number"
              fullWidth
              variant="standard"
              value={editModalData?.time_in_minute}
              onChange={(e) =>
                setEditModalData({
                  ...editModalData,
                  time_in_minute: parseInt(e.target.value),
                })
              }
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="edit_seconds"
              name="edit_seconds"
              label="Seconds"
              type="number"
              fullWidth
              variant="standard"
              value={editModalData?.time_in_seconds}
              onChange={(e) =>
                setEditModalData({
                  ...editModalData,
                  time_in_seconds: parseInt(e.target.value),
                })
              }
            />
          </Stack>
          {/* </Box> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={editCloseHandler}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Questions;
