import { io, Socket } from 'socket.io-client';

let socketIo: Socket;

const getSocketIo = () => {
  if (!socketIo) {
    socketIo = io('http://178.62.11.251:7200');
  }

  return socketIo;
};

export { getSocketIo };
