import axios, { AxiosResponse } from 'axios';
import FormData from 'form-data';
import { BASE_URL } from 'src/constants/constants';
import { AdminModel } from 'src/models/adminModel';
import store from 'src/store';

const postRequest = async (
  path: string,
  data: any
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const isLoggedIn: boolean = state.auth.isLoggedIn;
  const adminData: AdminModel = state.auth.adminData;

  try {
    response = await axios({
      method: 'POST',
      url: `${BASE_URL}${path}`,
      data,
      headers: {
        Authorization: `Bearer ${adminData?.token}`
      }
    });
  } catch (err) {
    console.log(`ERROR :: postRequest :: ${path} :: ${err}`);
    response = err.response;
  } finally {
    return response;
  }
};

const patchRequest = async (
  path: string,
  data: any
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const isLoggedIn: boolean = state.auth.isLoggedIn;
  const adminData: AdminModel = state.auth.adminData;

  try {
    response = await axios({
      method: 'PATCH',
      url: `${BASE_URL}${path}`,
      data,
      headers: {
        Authorization: `Bearer ${adminData?.token}`
      }
    });
  } catch (err) {
    console.log(`ERROR :: patchRequest :: ${path} :: ${err}`);
    response = err.response;
  } finally {
    return response;
  }
};

const deleteRequest = async (
  path: string
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const isLoggedIn: boolean = state.auth.isLoggedIn;
  const adminData: AdminModel = state.auth.adminData;

  try {
    response = await axios({
      method: 'DELETE',
      url: `${BASE_URL}${path}`,
      headers: {
        Authorization: `Bearer ${adminData?.token}`
      }
    });
  } catch (err) {
    console.log(`ERROR :: deleteRequest :: ${path} :: ${err}`);
    response = err.response;
  } finally {
    return response;
  }
};

const getRequest = async (
  path: string,
  data = {}
): Promise<AxiosResponse<any, any> | undefined> => {
  let response: AxiosResponse<any, any> = undefined;

  const state = store.getState();
  const isLoggedIn: boolean = state.auth.isLoggedIn;
  const adminData: AdminModel = state.auth.adminData;

  try {
    response = await axios({
      method: 'GET',
      url: `${BASE_URL}${path}`,
      params: data,
      headers: {
        Authorization: `Bearer ${adminData?.token}`
      }
    });
  } catch (err) {
    console.log(`ERROR :: getRequest :: ${path} :: ${err}`);
    response = err.response;
  } finally {
    return response;
  }
};

const login = async (data: { email: string; password: string }) => {
  const path = '/admin/auth/login';
  return postRequest(path, data);
};

const getAllTeams = async (data: { itemsPerPage: number; page: number }) => {
  const path = '/admin/team';
  return getRequest(path, data);
};

const setTeamTime = async (data: { timeInSeconds: number }) => {
  const path = '/admin/team/time';
  return postRequest(path, data);
};

const setTeamQuestion = async (data: { payload: object }) => {
  const formData = new FormData();
  for (let key in data.payload) {
    formData.append(key, data.payload[key]); 
  }
  const path = '/question_routes/questions';
  return postRequest(path, formData);
};


const updateTeamQuestion = async (data: { payload: object }) => {
  const formData = new FormData();
  for (let key in data.payload) {
    formData.append(key, data.payload[key]); 
  }
  const path = '/question_routes/update_questions';
  return postRequest(path, formData);
};


const getAllQuestion = async () => {
  const path = '/question_routes/get-questions';
  return getRequest(path);
};

const updateTeamTime = async (
  teamId: number,
  incrementTimeInSeconds: number
) => {
  const path = `/admin/team/time/increase/${teamId}`;
  return patchRequest(path, { incrementTimeInSeconds });
};

const playPauseAll = async (play: boolean) => {
  const path = `/admin/team/time/play-pause-all`;
  return patchRequest(path, { play });
};

const playPause = async (teamId: number, play: boolean, countDownInSeconds?: number) => {
  const path = `/admin/team/time/play-pause/${teamId}`;
  return patchRequest(path, { play, lastPauseAt: countDownInSeconds });
};

const deleteTeam = async (teamId: number) => {
  const path = `/admin/team/${teamId}`;
  return deleteRequest(path);
};

const getAllObjectTypes = async () => {
  const path = '/admin/collectibletype/getAllCollectibleTypes';
  return getRequest(path);
};

const getAllObjects = async (data: {
  itemsPerPage: number;
  page: number;
  search: string;
}) => {
  const path = '/admin/branding/getAllBrandings';
  return getRequest(path, data);
};

const addBranding = async (data: {
  title: string;
  description: string;
  latitude: number;
  longitude: number;
  height: number;
  collectible_type: string;
  message: string;
  branding_file: File;
  branding_thumbnail_file: File;
}) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }

  const path = '/admin/branding/addBranding';
  return postRequest(path, formData);
};

export {
  login,
  getAllTeams,
  setTeamTime,
  updateTeamTime,
  playPauseAll,
  playPause,
  getAllObjectTypes,
  getAllObjects,
  deleteTeam,
  addBranding,
  setTeamQuestion,
  getAllQuestion,
  updateTeamQuestion
};
