import {
  Card,
  CardHeader,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
  LinearProgress,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  TextField,
  AlertColor,
  Alert,
  Snackbar,
  Stack,
  ButtonGroup
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { TeamModel } from 'src/models/teamModel';
import {
  deleteTeam,
  getAllTeams,
  playPause,
  playPauseAll,
  setTeamTime,
  updateTeamTime
} from 'src/networking/network_calls';
import { getSocketIo } from 'src/socketio/socket-io';
import { secondsToMinuteSecond } from 'src/utils/Utils';

const Teams = (): JSX.Element => {
  type dialogOperation = 'SET_TIME' | 'ADD_TIME';

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [dialogTitle, setDialogTitle] = useState('');
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [teamsList, setTeamsList] = useState<TeamModel[]>([]);
  const [intervalList, setIntervalList] = useState<any[]>([]);

  const [open, setOpen] = React.useState(false);
  const [dialogOperationType, setDialogOperationType] =
    React.useState<dialogOperation>('SET_TIME');
  const [oprationForId, setOpetationForId] = React.useState(0);

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: '',
    severity: 'success'
  });

  const [countdowns, setCountdowns] = useState<{}>({});

  useEffect(() => {

    getSocketIo().on('updateCountdownOnAdmin', (data) => {

      if (data) {
        setCountdowns(prev => {
          const previosData = { ...prev };

          const jsonData = JSON.parse(data);

          if (jsonData && jsonData.countdown) {
            const minuteSecond = `${jsonData.countdown}`.split(':');

            previosData[
              `${jsonData.teamId}`
            ] = {
              countdown: `${minuteSecond[0]} minutes ${minuteSecond[1]} seconds`,
              countDownInSeconds: (parseInt(minuteSecond[0]) * 60) + parseInt(minuteSecond[1])
            };

            return previosData;
          }
        });

      }
    });

    const reverseCounter = (seconds: number, teamId: any) => {
      let timeLeft = seconds;

      const interval = setInterval(() => {
        timeLeft--;

        setCountdowns(prev => {
          const previosData = { ...prev };

          previosData[
            `${teamId}`
          ] = {
            countdown: secondsToMinuteSecond(timeLeft),
            countDownInSeconds: timeLeft
          };

          return previosData;

        });

        if (timeLeft === 0) {
          clearInterval(interval);
        }
      }, 1000);

      return interval;
    }
  }, []);



  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    const getData = async () => {
      const res = await getAllTeams({ itemsPerPage, page: page + 1 });

      if (res && res.status === 200) {
        setTotalDataCount(res.data.data.totalDataCount);
        const dataList: TeamModel[] = res.data.data.data;
        setTeamsList(dataList);
      }

      setLoading(false);
    };

    getData();
  }, [page, itemsPerPage]);

  const setTime = async (timeInSeconds: number) => {
    const res = await setTeamTime({ timeInSeconds });
    if (res && res.status === 201) {
      setSnackBarState({
        show: true,
        msg: 'Success',
        severity: 'success'
      });
    } else {
      setSnackBarState({
        show: true,
        msg: res.data['msg'] ?? 'Failed',
        severity: 'error'
      });
    }
  };

  const updateTime = async (timeInSeconds: number) => {
    const res = await updateTeamTime(oprationForId, timeInSeconds);

    if (res && res.status === 201) {
      setSnackBarState({
        show: true,
        msg: 'Success',
        severity: 'success'
      });
    } else {
      setSnackBarState({
        show: true,
        msg: res.data['msg'] ?? 'Failed',
        severity: 'error'
      });
    }
  };

  const setPlayPauseForAll = async (play: boolean) => {
    const res = await playPauseAll(play);

    if (res && res.status === 201) {
      const teams = [...teamsList];
      const updatedTeam = teams.map((val) => {
        val.play = play;
        return val;
      });

      setTeamsList(updatedTeam);

      setSnackBarState({
        show: true,
        msg: 'Success',
        severity: 'success'
      });
    } else {
      setSnackBarState({
        show: true,
        msg: res.data['msg'] ?? 'Failed',
        severity: 'error'
      });
    }
  };

  const setPlayPause = async (teamId: number, play: boolean) => {

    const countDownInSeconds = countdowns[teamId] && Object.hasOwn(countdowns[teamId], "countDownInSeconds") && countdowns[teamId]["countDownInSeconds"];

    const res = await playPause(teamId, play, countDownInSeconds);

    if (res && res.status === 201) {
      const teams = [...teamsList];
      const updatedTeam = teams.map((val) => {
        if (val.id === teamId) {
          val.play = play;
        }

        return val;
      });

      setTeamsList(updatedTeam);

      setSnackBarState({
        show: true,
        msg: 'Success',
        severity: 'success'
      });
    } else {
      setSnackBarState({
        show: true,
        msg: res.data['msg'] ?? 'Failed',
        severity: 'error'
      });
    }
  };

  const openSetAddTimeDialog = (type: dialogOperation, id: number) => {
    setOpetationForId(id);
    setDialogOperationType(type);

    if (type === 'SET_TIME') {
      setDialogTitle('Set initial time');
    } else {
      setDialogTitle('Add time');
    }
    setOpen(true);
  };

  const closeSetAddTimeDialog = () => {
    setOpen(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeItemsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  const deleteTeamById = async (id: number) => {
    const res = await deleteTeam(id);

    if (res && res.status === 200) {
      const teams = [...teamsList];
      const updatedTeam = teams.filter((val) => val.id !== id);

      setTeamsList(updatedTeam);

      setSnackBarState({
        show: true,
        msg: 'Team deleted',
        severity: 'success'
      });
    } else {
      setSnackBarState({
        show: true,
        msg: 'Failed to delete team',
        severity: 'error'
      });
    }
  };

  return (
    <Card sx={{ m: 2 }}>
      <Stack
        direction="row"
        gap={2}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <CardHeader
          subheaderTypographyProps={{}}
          titleTypographyProps={{}}
          title="Teams"
          subheader="All users present in the system"
        />
        <ButtonGroup>
          <Button
            variant="contained"
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              openSetAddTimeDialog('SET_TIME', 1);
            }}
            style={{
              marginRight: '10px'
            }}
          >
            Set Time
          </Button>
          <Button
            variant="contained"
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              setPlayPauseForAll(true);
            }}
            style={{
              marginRight: '10px'
            }}
          >
            Play All
          </Button>
          <Button
            variant="contained"
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              setPlayPauseForAll(false);
            }}
            style={{
              marginRight: '10px'
            }}
          >
            Pause All
          </Button>
        </ButtonGroup>
      </Stack>

      <Divider />
      <Snackbar
        open={snackBarState.show}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarState.severity}
          sx={{ width: '100%' }}
        >
          {snackBarState.msg}
        </Alert>
      </Snackbar>
      {loading && <LinearProgress />}
      {!loading && (
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Timezone</TableCell>
                  {/* <TableCell>Set Time</TableCell> */}
                  <TableCell>Add Time</TableCell>
                  <TableCell>Play/Pause</TableCell>
                  <TableCell>Countdown</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamsList.map((data: TeamModel) => (
                  <TableRow key={data.id} hover>
                    <TableCell>{data.name}</TableCell>
                    <TableCell>{data.timezone}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                          openSetAddTimeDialog('ADD_TIME', data.id);
                        }}
                      >
                        Add Time
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                          setPlayPause(data.id, !data.play);
                        }}
                      >
                        {data.play ? 'Pause' : 'Play'}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {!data.play && countdowns[data.id] && Object.hasOwn(countdowns[data.id], "countdown") && countdowns[data.id]["countdown"]}
                      {!data.play && (!countdowns[data.id] || !Object.hasOwn(countdowns[data.id], "countdown")) && secondsToMinuteSecond(data.lastPausedAt)}
                      {data.play && countdowns[data.id] && countdowns[data.id]["countdown"] || ''}

                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                          deleteTeamById(data.id);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={totalDataCount}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeItemsPerPage}
              page={totalDataCount <= 0 ? 0 : page}
              rowsPerPage={itemsPerPage}
              rowsPerPageOptions={[5, 10, 15, 20]}
            />
          </Box>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={closeSetAddTimeDialog}
        PaperProps={{
          component: 'form',
          // @ts-ignore
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const minutes = formJson.minutes;
            const seconds = formJson.seconds;

            const timeInSeconds =
              parseInt(`${minutes}`) * 60 + parseInt(`${seconds}`);

            switch (dialogOperationType) {
              case 'SET_TIME':
                setTime(timeInSeconds);
                break;
              case 'ADD_TIME':
                updateTime(timeInSeconds);
            }

            closeSetAddTimeDialog();
          }
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>          
          <Box display={'flex'} justifyContent={'space-between'} gap={2}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="minutes"
              name="minutes"
              label="Minutes"
              type="number"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="seconds"
              name="seconds"
              label="Seconds"
              type="number"
              fullWidth
              variant="standard"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSetAddTimeDialog}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Teams;
